import * as React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import {formatDate} from "../helpers/date"
import Layout from "../components/layout"
import HeaderScriptsComponent from "../components/headerScripts";

export const query = graphql`
    query ($uid: String) {
        entry: craftBlogPostsDefaultEntry(uid: {eq: $uid}) {
            seomatic {
                ... on Craft_SeomaticType {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                    metaSiteVarsContainer
                }
            }
            title
            postDate
            blogPostBody
        }
    }
`

const BlogPostTemplate = ({data}) => {
    return (
        <Layout seomatic={data.entry.seomatic}>
            <section className="page-hero-section section-regular bg-white page-hero-section-bg pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="hero-section-content text-center mobile-py-20">
                                <h1 className="f-midium mobile-f-40">{data.entry.title}</h1>
                                <p className="mb-0 text-uppercase h1 border-0">{formatDate(data.entry.postDate)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-hero-section-bg page-hero-section-bg-scale">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            <div className="box-with-border bg-white">
                                <div className="et_pb_text_inner">
                                    {parse(data.entry.blogPostBody)}
                                    <hr/>
                                    <p>
                                        <em>Cordiance LLC maintains this website exclusively for informational purposes. It is not professional advice and does not necessarily represent the opinion of Cordiance LLC or its clients. Viewing this site, using information from it, or digitally communicating with Cordiance LLC through this site does not create a client relationship between you and Cordiance LLC.</em>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-90 pt-60">
                        <div className="col-md-12 text-center">
                            <a className="btn btn-primary" href="/blog">Return to Blog</a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BlogPostTemplate

export const Head = ({data}) => (
    <HeaderScriptsComponent data={data} />
)